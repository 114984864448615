import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { WhatsNewTabPanel, WhatsNewTabs } from './WhatsNewTabs';
import { Update } from './Update';
import { Header } from './Header';
import { Footer } from './Footer';

export const OkrUpdate: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const [tabSelected, setTabSelected] = useState<Number>(0);

	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};

	return (
		<Box className='whats-new-wrapper'>
			{!props?.isUserLoggedIn ? <Header isUserLoggedIn={props?.isUserLoggedIn} /> : <></>}
			<WhatsNewTabs {...props} tabSelected={tabSelected} handleTabChange={handleTabChange} />
			<Box className='whats-new-tabpanel-wrap'>
				<WhatsNewTabPanel value={tabSelected} index={0}>
					<Update {...props} />
				</WhatsNewTabPanel>
				<WhatsNewTabPanel value={tabSelected} index={1}>
					Coming Soon
				</WhatsNewTabPanel>
			</Box>
			{!props?.isUserLoggedIn ? <Footer /> : <></>}
		</Box>
	);
};
